$main_color : #009988;
$main_color_light: #B2DFDB;
$second_color: #795548;
$main_text_color: #212121;
$second_text_color: #757575;
$border_color: #BDBDBD;

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-box-shadow: none;
  box-shadow: none;   
  outline: none;  
}

html {
  background-color:#F7F7F8;
  color:$main_text_color;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root {
  padding:1em 0;
  padding-top: 4.5em;
  height:100%;
  box-sizing:border-box;
  position:relative;
  line-height: 1.5em;
  overflow-y:auto;
}

.miniRoot {
  padding:1em 0;
  padding-top: 2.5em;
  width: 100%;
  max-width:480px;
  margin:0 auto;
  box-sizing:border-box;
  line-height: 1.5em;
}

.smallRoot {
  padding:1em 0;
  padding-top: 2.5em;
  width: 100%;
  max-width:600px;
  margin:0 auto;
  box-sizing:border-box;
  line-height: 1.5em;
}

.mediumRoot {
  padding:1em 0;
  padding-top: 4.5em;
  width: 100%;
  max-width:960px;
  margin:0 auto;
  box-sizing:border-box;
  line-height: 1.5em;
}

.modal {
  position:absolute;
  width:100%;
  height:100%;
  max-width:460px;
  max-height:620px;
  background-color:#111111;
  box-sizing:border-box;
}

.modalRoot {
  height:100%;
  box-sizing:border-box;
  overflow-x:hidden;
  overflow-y:auto;
  padding-top: 1em;
  line-height: 1.4em;
  padding-right: 1em;
  padding-left: 1em;
  border:1px solid #CCCCCC;
}

.modalHead {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  padding:0em;
  padding-top:0.1em;
  line-height:1em;
  box-sizing:border-box;
  background-color:#393F4F;
  z-index:999;
}

.dialogRoot {
  box-sizing:border-box;
  padding: 1em;
  border:1px solid #CCCCCC;
  h2 {
    font-size:120%;
    margin:0;
    padding:0;
    margin-bottom:1em;
  }
}

.pickerRoot {
  box-sizing:border-box;
  padding: 1em;
  max-height:100%;
  box-sizing:border-box;
  h2 {
    font-size:120%;
    margin:0;
    padding:0;
    margin-bottom:1em;
  }
}

.alert {
  position:absolute;
  width:100%;
  max-width:240px;
  background-color:#CCCCCC;
  box-sizing:border-box;
  //filter: drop-shadow(3px 3px 2px rgba(0,0,0,0.6));
}

.about {
  position:absolute;
  width:100%;
  max-width:300px;
  background-color:#FFFFFF;
  box-sizing:border-box;
  //filter: drop-shadow(3px 3px 2px rgba(0,0,0,0.6));
  h2 {
    margin:0;
    margin-bottom:0.5em;
    font-size:100%;
    font-weight:normal;
    color:$second_color;
  }
  div.text {
    font-size:90%;
    margin-bottom:2em;
    p.copyright {
      text-align:center;
      margin-top:3em;
    }
  }
}

.bottomApp {
  position:fixed;
  bottom:1em;
  left:0;
  box-sizing:border-box;
  width:100%;
  padding:0 1em;
}

div.nextBtns {
  text-align:center;
  margin-top:1em;
}

.title {
  color:$main_color;
  margin-bottom:0.5em!important;
}

.withIcon {
  display:inline-flex;
  vertical-align:middle;
}

.clickable {
  cursor:pointer;
}

.wide {
  width:100%;
}

.formLabel {
  color: $second_color!important;
}

.paper {
  padding:2em 1em;
  padding-bottom:6em;
  background-color:white;
  border-radius:0.5em 0.5em 0 0;
  line-height:1.8em;
  font-size:90%;
}

.paperTitle {
  margin-bottom:2em;
}

.talk {
  margin-bottom:2em;
  p {
    margin-bottom:1em;
  }
}

.calendar {
  position:absolute;
  width:100%;
  min-width:300px;
  max-width:360px;
  padding-top:2em;
  background-color:#FFFFFF;
  box-sizing:border-box;
}

.tagPicker {
  position:absolute;
  width:100%;
  min-width:300px;
  max-width:420px;
  padding-top:2em;
  background-color:#FFFFFF;
  box-sizing:border-box;
  div.dialogRoot {
    min-height:200px;
  }
}

.profileInput {
  position:absolute;
  width:100%;
  min-width:300px;
  max-width:420px;
  padding-top:2em;
  background-color:#FFFFFF;
  box-sizing:border-box;
}

.base {
  position:relative;
}

.removeBtn {
  text-align:right;
  cursor:pointer;
  z-index:999;
}

div.colData {
  position:relative;
  div.name {
    background-color:#EEEEEE;
    padding:0 0.5em;
    font-weight:400;
  }
  div.value {
    padding:0;
    margin-bottom:1.5em;
  }
  div.btn {
    position:absolute;
    right:0;
    top:0;
  }
  div.name_w {
    background-color:#FFFFFF;
    padding:0;
    font-weight:700;
  }
  div.value_w {
    padding:0;
    margin-bottom:1em;
  }
}

div.reportTags {
  div.tag {
    float:left;
    //display:inline;
    //background-color:$second_color;
    border:1px solid $second_color;
    color:$second_color;
    padding:0.1em 0.5em;
    border-radius:0.5em;
    margin-right:0.5em;
    margin-bottom:0.5em;
    font-size:90%;
    span.btn {
      margin-left:1em;
      cursor:pointer;
    }
  }
  div.selectable {
    cursor:pointer;
  }
}

div.reportTagsAfter {
  clear:both;
  margin-bottom:3em;
}

div.profile {
  h2 {
    font-size:120%;
    border-bottom:1px dashed $border_color;
    padding:0;
    margin:0;
    margin-bottom:1em;
    padding-bottom:0.5em;
  }
}

div.reports {
  padding-bottom:6em;
  h2 {
    font-size:120%;
    border-bottom:1px dashed $border_color;
    padding:0;
    margin:0;
    margin-bottom:1em;
    padding-bottom:0.5em;
  }
  div.box {
    position:relative;
    color: $main_text_color;
    div.date {
      position:absolute;
      left:0;
      top:0.5em;
      width:5.5em;
      div.year {
        font-size:80%;
        color:$second_text_color;
      }
      div.menu {
        margin-left:-0.3em;
      }
    }
    div.text {
      line-height:1.75em;
      margin-left:5.5em;
      border-left:6px solid $main_color_light;
      padding-top:0.5em;
      padding-left:1em;
      padding-bottom:4em;
      min-height:3em;
      ul.tags {
        //border:1px solid silver;
        padding:0.5em 0em;
        margin:0;
        list-style:none;
        li {
          padding:0;
          margin:0;
          //margin-left:1em;
          font-size:90%;
          color:$second_color;
          a {
            text-decoration:none;
            color:$second_color;
          }
        }
      }
      div.btns {
        text-align:right;
      }
    }
  }
  div.smallBox {
    position:relative;
    margin-bottom:2em;
    color: $main_text_color;
    div.date {
      position:relative;
      border-bottom:6px solid $main_color_light;
      line-height:1em;
      padding-bottom:0.2em;
      div.year {
        position:absolute;
        bottom:0.1em;
        right:2.5em;
        font-size:80%;
        color:$second_text_color;
      }
      div.menu {
        position:absolute;
        top:-0.4em;
        right:0em;
      }
    }
    div.text {
      padding:1em 0em;
      line-height:1.75em;
      min-height:3em;
      ul.tags {
        //border:1px solid silver;
        padding:0em;
        margin:0;
        margin-top:1em;
        //margin-left:1em;
        list-style:none;
        li {
          padding:0;
          margin:0;
          //margin-left:1em;
          font-size:90%;
          color:$second_color;
          a {
            text-decoration:none;
            color:$second_color;
          }

        }
      }
    }
  }

  div.tagBox {
    position:relative;
    border-bottom:1px solid $border_color;
    margin-bottom:1em;
    div.name {
      color:$second_color;
    }
    div.days {
      font-size:80%;
      color:$second_text_color;
      span.count {
        margin-right:1em;
        border-right:1px dashed $border_color;
        //color:$second_color;
        padding:0;
        padding-right:1em;
      }
    }
    div.btns {
      position:absolute;
      right:0;
      bottom:0;
    }
  }

  div.more {
    padding-top:2em;
  }
}

div.search {
  border-top:1px dashed $border_color;
  padding-top:2em;
  background-color:white;
  border-radius:0 0 0.5em 0.5em;
  div.btns {
    text-align:center;
  }
}

.mailLoginInput {
  border:1px solid $border_color;
  border-radius:0.5em;
  padding:1em;
  margin-top:1em;
}

.hint {
  margin:1em 0;
  color:$second_color;
  font-size:90%;
}

.goMain {
  margin-top:1em;
  text-align:center;
}

h2.withSearch {
  position:relative;
  div.goSearch {
    position:absolute;
    bottom:0;
    right:0;
    cursor:pointer;
    a {
      color:$main_color;
    }
  }
}

.link {
  text-decoration:none;
}

div.copyright {
  margin-bottom:2em;
  text-align:right;
  color:$second_text_color;
  font-size:90%;
}

.first {
  padding:1em 0;
  margin-bottom:4em;
  h2 {
    color:$second_color;
    margin:0;
    margin-bottom:1em;
    font-size:100%;
    font-weight:normal;
  }
  p {
    line-height:1.7em;
    margin:0;
    margin-bottom:0.5em;
  }
  div.sample {
    text-align:center;
    margin-top:3em;
  }
}